<template>
  <div
    class="scroll-top-button"
    @click="scrollTop"
    :class="{ 'is-visible': isVisible }"
  >
    <ChevronBlackSmallDownIcon class="scroll-top-button__image" />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import { ChevronBlackSmallDownIcon } from '~/components/General/Icons';

export default defineComponent({
  name: 'ScrollToTop',
  components: { ChevronBlackSmallDownIcon },
  setup() {
    const isVisible = ref(false);
    const scrollTop = () => {
      if (window) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };
    onMounted(() => {
      window.addEventListener('scroll', () => {
        window.scrollY > window.screen.height
          ? (isVisible.value = true)
          : (isVisible.value = false);
      });
    });
    return {
      isVisible,
      scrollTop,
    };
  },
});
</script>

<style lang="scss" scoped>
.scroll-top-button {
  z-index: 5;
  border-radius: 50%;
  border: 0.0625rem solid var(--c-black-40);
  background: var(--c-white);
  position: fixed;
  @include for-desktop {
    bottom: 0.625rem;
    right: 9.5rem;
  }
  @include for-mobile {
    right: 0.625rem;
    bottom: 3.5rem;
  }
  width: 1.875rem;
  height: 1.875rem;
  visibility: hidden;
  &.is-visible {
    visibility: visible;
    transition: visibility 1s ease-in-out;
  }
  &:hover {
    cursor: pointer;
  }
  &__image {
    transform: rotate(180deg);
    margin: 0.625rem 0.5rem;
  }
}
</style>
