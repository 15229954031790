var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"geolocator-modal-container"},[_c('SfModal',{staticClass:"geolocator-modal",attrs:{"persistent":true,"visible":_vm.isGeoLocatorModalOpen},on:{"close":function () {
        _vm.updateCookie();
        _vm.toggleGeoLocatorModal();
      }},scopedSlots:_vm._u([{key:"close",fn:function(){return [_c('CrossIcon')]},proxy:true},{key:"modal-bar",fn:function(){return [_c('div')]},proxy:true}])},[_vm._v(" "),_vm._v(" "),_c('div',{staticClass:"geolocator-modal-content"},[_c('div',{staticClass:"geolocator-modal-body"},[_c('div',{staticClass:"geolocator-modal-text-top"},[_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('You seem to be connected from '))),_c('b',[_vm._v(_vm._s(_vm.$t(("" + _vm.countryLabel))))])]),_vm._v(" "),(_vm.countryFlag)?_c('nuxt-img',{attrs:{"src":_vm.countryFlag,"format":"webp","width":"20px","height":"13px","alt":"flag","loading":"lazy"}}):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"geolocator-modal-bottom"},[_c('CustomButton',{attrs:{"text":_vm.$t('Continue navigating')},on:{"click":function () {
              _vm.updateCookie();
              _vm.toggleGeoLocatorModal();
            }}}),_vm._v(" "),_c('CustomButton',{attrs:{"text":_vm.$t('Change country')},on:{"click":function () {
              _vm.updateCookie();
              _vm.toggleGeoLocatorModal();
              _vm.toggleCountrySidebar();
            }}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }