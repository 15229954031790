export { default as AlertIcon } from '~/static/icons/components/alert.svg';
export { default as BagIcon } from '~/static/icons/components/bag.svg';
export { default as ChevronBlackBigLeftIcon } from '~/static/icons/components/chevron_black_big_left.svg';
export { default as ChevronBlackSmallDownIcon } from '~/static/icons/components/chevron_black_small_down.svg';
export { default as ChevronBlackSmallRightIcon } from '~/static/icons/components/chevron_black_small_right.svg';
export { default as ChevronGreyBigRightIcon } from '~/static/icons/components/chevron_grey_big_right.svg';
export { default as CrossIcon } from '~/static/icons/components/cross.svg';
export { default as DotBlackIcon } from '~/static/icons/components/dot_black.svg';
export { default as DotGreyIcon } from '~/static/icons/components/dot_grey.svg';
export { default as HamburgerIcon } from '~/static/icons/components/hamburger.svg';
export { default as HeartEmptyIcon } from '~/static/icons/components/heart_empty.svg';
export { default as HeartFullIcon } from '~/static/icons/components/heart_full.svg';
export { default as MinusIcon } from '~/static/icons/components/minus.svg';
export { default as PlusIcon } from '~/static/icons/components/plus.svg';
export { default as RulerIcon } from '~/static/icons/components/ruler.svg';
export { default as SearchIcon } from '~/static/icons/components/search.svg';
export { default as StarIcon } from '~/static/icons/components/star.svg';
export { default as WhiteCircleIcon } from '~/static/icons/components/white_circle.svg';
export { default as ArrowRightIcon } from '~/static/icons/components/arrow_right.svg';
export { default as ProductPlaceholder } from '~/static/icons/components/product_placeholder.svg';
export { default as ZoomCursor } from '~/static/icons/components/zoom_cursor.svg';
export { default as FiltersCross } from '~/static/icons/components/filters_cross.svg';
export { default as PasswordEyeHidden } from '~/static/icons/components/password-hidden.svg';
export { default as PasswordEyeShown } from '~/static/icons/components/password-shown.svg';
